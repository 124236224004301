import * as React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/SEO';
import BannerModule from '../components/BannerModule/BannerModule';
import BasicTextModule from '../components/BasicTextModule/BasicTextModule';
import PerksModule from '../components/PerksModule/PerksModule';
import Perk from '../components/PerksModule/Perk';
import Features from '../components/Features/Features';
import LatestPosts from '../components/Post/LatestPosts';

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Orix Au Yeung"
          subTitle="Machine Learning Engineer | Technology enthusiast"
          enquire={false}
        />
        <BasicTextModule
          title="A machine learning engineer with unrelenting passion"
          content="Experienced software engineer specialized in Machine Learning and Cloud technologies.
          Firm believer and Evangelist of Open Source software movement. Regular public speaker.
          Self-motivated with an insatiable intellectual curiosity."
          link="/about"
          linkText="More About Me"
        />
        <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule>
        <Features
          title="Featured Projects"
          introduction="Some of most interesting projects I have worked on previously.
          Click into each image for more details."
        />
        <LatestPosts
          title="Latest Blog Posts"
          introduction="Anything work related, updates, random thoughts and things worth sharing."
        />
      </Layout>
    </>
  );
};

export default Index;
